import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Hero } from "../components/hero"

export const query = graphql`
  query HomePageQuery {
    file(name: { eq: "bostonian-electric-electrician-working" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
const IndexPage = ({ data }) => {
  const banner = data.file.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="Home" />
      <Hero
        image={banner}
        alt="Electrician working on wires"
        header="Bostonian Electric"
        firstButtonLabel="Services"
        firstButtonLink="/services/"
        highlightFirstButton={true}
        secondButtonLabel="Get a quote"
        secondButtonLink="/contact/"
        highlightSecondButton={false}
      />
      <section className="container my-6 sm:my-12 mx-auto px-4 font-sans text-center">
        <hgroup>
          <h2>Welcome to Bostonian Electric</h2>
          <p>Greater Boston's premier electrical contractors.</p>
        </hgroup>
        <p>
          Please{" "}
          <Link
            className="text-blue-700 hover:underline"
            to="/contact/"
            title="contact"
          >
            contact us
          </Link>{" "}
          for a quote.
        </p>
      </section>
    </Layout>
  )
}
export default IndexPage
